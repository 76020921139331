import * as BackendAPI from '../BackendAPI/index'
import * as Utils from '../Utils/index'
import { LOGOUT_USER } from './User'


const initialStateOrg = {
    kitchens: null,
    org: {},  //JSON
    currentKitchen: null,
    selectedKitchen: null,
    isLoading: false,
    loadingMessage: 'Laddar kök...',
    isLoadingCurrentKitchen: false,
    loadingCurrentKitchenMessage: 'Läser in valt kök...',
    error: null,
    lastWasteUpdate: null,
    lastRegisterAllKitchenDataUpdate: null
  }

  const LOAD_ORG_START = 'LOAD_ORG_START'
  const LOAD_ORG_SUCCESS = 'LOAD_ORG_SUCCESS'
  const LOAD_ORG_ERROR = 'LOAD_ORG_ERROR'

  const LOAD_CURRENT_KITCHEN_START = 'LOAD_CURRENT_KITCHEN_START'
  const LOAD_CURRENT_KITCHEN_SUCCESS = 'LOAD_CURRENT_KITCHEN_SUCCESS'
  const LOAD_CURRENT_KITCHEN_ERROR = 'LOAD_CURRENT_KITCHEN_ERROR'

  const SELECT_KITCHEN = 'SELECT_KITCHEN'

  const UPDATE_WASTE = 'UPDATE_WASTE'
  const REGISTER_ALL_KITCHEN_DATA = 'REGISTER_ALL_KITCHEN_DATA'

  const loadOrgStart = () => {
    return {
      type: LOAD_ORG_START
    }
  }
  
  const loadOrgSuccess = (kitchens, org) => {
    return {
      type: LOAD_ORG_SUCCESS,
      kitchens,
      org
    }
  }
  
  const loadOrgFailure = (error) => {
    return {
      type: LOAD_ORG_ERROR,
      error
    }
  }

  const loadCurrentKitchenStart = () => {
    return {
      type: LOAD_CURRENT_KITCHEN_START
    }
  }
  
  const loadCurrentKitchenSuccess = (currentKitchen, id) => {
    return {
      type: LOAD_CURRENT_KITCHEN_SUCCESS,
      currentKitchen,
      id
    }
  }
  
  const loadCurrentKitchenFailure = (error) => {
    return {
      type: LOAD_CURRENT_KITCHEN_ERROR,
      error
    }
  }

  /*export const selectKitchenThunk = (id) => (dispatch) => {
    dispatch(selectKitchen(id));
    return 
  }*/

  export const updateWaste = () => {
    //console.log('Got updateWaste')
    return {
      type: UPDATE_WASTE
    }
  }

  export const registerAllKitchenData = () => {
    //console.log('Got registerAllKitchenData')
    return {
      type: REGISTER_ALL_KITCHEN_DATA
    }
  }

  

  export const loadOrg = (id) => (dispatch) => {
    let token = Utils.getToken()
    if (!token) {
      console.log('No token, no load org');
      return
    } else if (!id) {
      console.log('No id, no load org');
    } else {
      // Fire start action to display a loading indicator for example
      dispatch(loadOrgStart());
      // Return the promise the thunk middleware
      return BackendAPI.loadOrg(id).then(   
        // on success, fire successful action.
        response => {
          //console.log('----- RESPONSE ', response)
          let kitchens = response[0].data
          
          // Because async, this request may randomly return json structure or just name, depending on if logged in
          // But we're only interested in name anyway
          let org = response[1].data["name"] ? response[1].data : {"name": response[1].data}
          dispatch(loadOrgSuccess(kitchens, org))},

        // on failure, fire failure action to e.g. show error message to user
        error => dispatch(loadOrgFailure(error))
      )
    }
  }


  export const selectKitchen = (id) => (dispatch) => {
    return {
      type: SELECT_KITCHEN,
      id
    }
  }

  export const selectAndLoadKitchen = (id) => (dispatch) => {
      dispatch(loadCurrentKitchenStart());
      return BackendAPI.loadKitchen(id).then(   
        response => {
          dispatch(loadCurrentKitchenSuccess(response.data, response.data.id))},
        error => dispatch(loadCurrentKitchenFailure(error))
      )
    
  }
  
  export default function reducer(state = initialStateOrg, action) {
        switch (action.type) {
          case LOAD_ORG_START:
              return {
                ...state,
                isLoading: true,
                error: null
              };
          case LOAD_ORG_SUCCESS:
              return {
                  ...state,
                  isLoading: false,
                  org: action.org,
                  kitchens: action.kitchens,
                  error: null
              };
          case LOAD_ORG_ERROR:
            console.log('Error response hämta ORG: ', action.error);
            Utils.notify('error', 'Fel när org skulle laddas: ' + action.error.message);
              return {
                  ...state,
                  isLoading: false,
                  error: action.error,
              };
          case LOAD_CURRENT_KITCHEN_START:
            return {
              ...state,
              isLoadingCurrentKitchen: true,
              error: null
            };
          case LOAD_CURRENT_KITCHEN_SUCCESS:
              return {
                  ...state,
                  isLoadingCurrentKitchen: false,
                  currentKitchen: action.currentKitchen,
                  selectedKitchen: action.id,
                  error: null
              };
          case LOAD_CURRENT_KITCHEN_ERROR:
            console.log('Error response hämta Current kitchen: ', action.error);
            Utils.notify('error', 'Fel när valt kök skulle laddas: ' + action.error.message);
              return {
                  ...state,
                  isLoadingCurrentKitchen: false,
                  error: action.error,
              };
          case SELECT_KITCHEN:
            return {
                ...state,
                selectedKitchen: action.id
            };
          case UPDATE_WASTE:
            return {
                ...state,
                lastWasteUpdate: Date.now()
            };
            case REGISTER_ALL_KITCHEN_DATA:
            return {
                ...state,
                lastRegisterAllKitchenDataUpdate: Date.now()
            };
            case LOGOUT_USER:
              return {...initialStateOrg}
          default:
              return state;
        }
    }

export const getTopKitchens = (org) => {
  var topKitchens = Object.keys(org.kitchens)
  return topKitchens;
}



   