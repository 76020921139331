import axios from 'axios';
import * as Utils from '../Utils/index'

let token = Utils.getToken()
const BE_SERVER = process.env.REACT_APP_BE_SERVER_SYSTEM || process.env.REACT_APP_BE_SERVER
//const BE_SERVER = 'test.matsvinn.se'

const formatDate = (date) => {return date.toISOString().split('T')[0]}
const formatDateMs = (date) => {return (''+date.getTime()).substring(0,10)}

export const remoteLogin = (bodyFormData) => {
  return axios({
    method: 'post',
    url: 'https://' + BE_SERVER + '/api/login_remote',
    data: bodyFormData,
    maxRedirects: 0,
    headers: {'Content-Type': 'application/json' }
  })
}

export const loadCurrentUser = () => {
    console.log('LOADING USER');
    return axios({
        method: 'get',
        url: 'https://' + BE_SERVER + '/api/logged_in_user',
        maxRedirects: 0,
        headers: {'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' +  token}
      })
      /*.then(function (response) {
        console.log('SUCCESS %o', response);
        return response;
      })
      .catch(function (error) {
        console.log('ERROR', error);
        return error;
      });*/
}

export const loadKitchen = (id) => {
  //console.log('LOADING KITCHEN');
  return axios({
      method: 'get',
      url: 'https://' + BE_SERVER + '/api/kitchen/' + id,
      headers: {'Content-Type': 'application/json',
                'Authorization': 'Bearer ' +  token}
    })
}


export const loadOrg = (org_id) => {
    //console.log('LOADING ORG');
    return axios.all([
    
      axios({
        method: 'get',
        //url: 'https://' + BE_SERVER + '/api/kitchens/' + org_id,
        url: 'https://' + BE_SERVER + '/api/organization/' + org_id + '/kitchen_tree',
        maxRedirects: 0,
        headers: {'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' +  token}
      }),
        axios({
          method: 'get',
          url: 'https://' + BE_SERVER + '/api/organization/' + org_id,
          maxRedirects: 0,
          headers: {'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' +  token}
        })
    ])
    
}

export const loadOrgName = (org_id) => {
  console.log('LOADING ORG');
  return axios({
      method: 'get',
      url: 'https://' + BE_SERVER + '/api/organization/' + org_id,
      maxRedirects: 0,
      headers: {'Content-Type': 'application/json',
                'Authorization': 'Bearer ' +  token}
    })
}

export const registerWaste = (wastetype, kitchenId, weight, mealtypeId, procId, categoryId, selectedDate, totalWeight = 0) => {
  console.log('registerWaste');

  // Common data for all requests
  const data = {
    kitchen_id: kitchenId,
    timestamp: selectedDate.toISOString().replace(/T/, ' ').replace(/\..+/, ''),
    //weight: weight,
    mealtype_id: mealtypeId,
    //process_id: procId,
    //category_id: categoryId
  }

  // Prevent negative weights in aggregates
  let actualWeight = Math.round((weight + Number.EPSILON) *100)/100
  if(actualWeight < 0){
    if( totalWeight - Math.abs(actualWeight) < 0 ){
      console.warn('Input weight',actualWeight,' is lower than existing total weight', totalWeight,'. It is changed to ',-1*totalWeight,'so that the aggregate is not negative.')
      actualWeight = -1*totalWeight
    }
  }

  let url = ''
  if (wastetype === Utils.WasteTypeWaste) {
    url = 'https://' + BE_SERVER + '/api/waste'
    data.weight = actualWeight
    data.process_id = procId
    data.category_id = categoryId
  } else if (wastetype === Utils.WasteTypeServing) {
    url = 'https://' + BE_SERVER + '/api/serving'
    data.weight = actualWeight
    data.process_id = procId
    data.category_id = categoryId
  } else if (wastetype === Utils.WasteTypePortionsCooked) {
    // Portions does not have processes or categories, so no need to set those ids
    url = 'https://' + BE_SERVER + '/api/portion'
    data.ordered_portion = actualWeight
  } else if (wastetype === Utils.WasteTypePortionsServed) {
    // Portions does not have processes or categories, so no need to set those ids
    url = 'https://' + BE_SERVER + '/api/portion'
    data.portion = actualWeight
  } else {
    throw new Error('not valid wastetype')
  }

  //console.log('sending data: ', data)
  return axios({
    method: 'post',
    url: url,
    headers: {'Content-Type': 'application/json',
              'Authorization': 'Bearer ' +  token},
              data: data
  })
}

export const getAggregate = (agg_id) => {
  console.log('LOADING getAggregate');
  return axios({
      method: 'get',
      url: 'https://' + BE_SERVER + '/api/waste_aggregate/' + agg_id,
      headers: {'Content-Type': 'application/json',
                'Authorization': 'Bearer ' +  token}
    })
}

/*export const getWastesByDateAndKitchen = (date, kitchenId) => {
  console.log('LOADING getWastesByDateAndKitchen');
  return axios({
      method: 'get',
      url: 'https://' + BE_SERVER + '/api/waste_date/' + date.toISOString().split('T')[0] + '/' + kitchenId,
      headers: {'Content-Type': 'application/json',
                'Authorization': 'Bearer ' +  token}
    })
}*/

export const getAggregatesByDateAndKitchen = (date, kitchenId) => {
  console.log('LOADING getAggregatesByDateAndKitchen');
  return axios({
      method: 'get',
      url: 'https://' + BE_SERVER + '/api/kitchen/' + kitchenId + '/aggregate/date/' + formatDate(date),
      headers: {'Content-Type': 'application/json',
                'Authorization': 'Bearer ' +  token}
    })
}

export const getStats = (kitchenId, periodicity, startDate, endDate) => {
  const aggregateURL = 'https://' + BE_SERVER + '/api/kitchen/'+kitchenId+'/aggregate/daterange/' + formatDate(startDate) + '/' + formatDate(endDate) + '?timescale=' + periodicity
  //console.log('LOADING getStats: https://' + BE_SERVER + '/api/kitchen/' + kitchenId + '/daterange_timescale_data/' + periodicity + '/' + formatDateMs(startDate) + '/' + formatDateMs(endDate));
  //console.log('LOADING DATA', aggregateURL)
  return axios({
      method: 'get',
      url: aggregateURL,
      //url: 'https://' + BE_SERVER + '/api/kitchen/' + kitchenId + '/daterange_timescale_data/' + periodicity + '/' + formatDateMs(startDate) + '/' + formatDateMs(endDate),
      headers: {'Content-Type': 'application/json',
                'Authorization': 'Bearer ' +  token}
    })
}



