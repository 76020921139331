import {BrowserRouter as Router, Route, Redirect, Switch, useLocation, useHistory, useParams} from 'react-router-dom';
import React, {Suspense, lazy, Fragment, Component, useEffect, useState} from 'react';
import * as Utils from '../../Utils/index.js'
import {connect} from 'react-redux';
import { loadUser } from '../../reducers/User'
import { loadOrg } from '../../reducers/Organisation'
import Loader from 'react-loaders'

import {
    ToastContainer,
} from 'react-toastify';

const Waste = lazy(() => import('../../Pages/Waste'));
const Report = lazy(() => import('../../Pages/Report'));
const LoginFullPage = lazy(() => import('../../Pages/LoginFullPage'));
const Home = lazy(() => import('../../Pages/Home'));

const ProtectedRoute = (props) => {
    //console.log('Protected token: ' + props.token)
    
    if (!Utils.getToken()) {
        return (
            <Fragment>
            <Route {...props} exact path="/" render={() => (
                    <Redirect to="/login"/>
                )}/>
                </Fragment>
        )
    } else {
        //console.log('..P2')
        return (
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-5">
                            Laddar sida...
                        </h6>
                    </div>
                </div>
            }>
                <Router>
                    <Switch>
                        <Route exact path="/home" component={Home}/>
                        <Route exact path="/svinn/"><Waste /></Route>
                        <Route path="/svinn/:kitchenId"><Waste /></Route>
                        <Route exact path="/rapport" component={Report}/>
                        <Route path="/" render={() => (
                            <Redirect to="/home"/>
                        )}/>
                    </Switch>
                </Router>
            </Suspense>
        )
    }
}

const AppMain = (props) => {

//console.log('NEW Appmain')
let location = useLocation();
let urlToken = location.search.replace("?token=", "");
let history = useHistory()
if (urlToken) {
    //console.log('GOT TOKEN: ', urlToken)
    Utils.setToken(urlToken)
    //history.push({
    //    pathname: '/svinn'
    //})
}

let user = props.user
let org = props.org
let loadOrg = props.loadOrg
let loadUser = props.loadUser

useEffect(() => {
    if (Utils.getToken()) {
        //console.log('---has token')
        //if (props.user && props.user.email) {
        if (user && user.email) {
            //console.log('---has user')
        } else {
            //console.log('---no user', user)
            loadUser();
        }

        if (user && user.organization_id)
        loadOrg(user.organization_id);
    }
}, [user, loadOrg, loadUser]);

//console.log('PROPS: ', props)

if (urlToken) {
    console.log('redirecting!')
    history.push({
           pathname: '/svinn'
        })
    window.location.reload()
    //return <Redirect to='/svinn' />
}

return (
    <Fragment>
        <ToastContainer/>
        <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="pacman"/>
                        </div>
                        <h6 className="mt-5">
                            Laddar sida
                            <small></small>
                        </h6>
                    </div>
                </div>
            }>
    <Router>
    <Switch>
            <Route exact path="/home">
                <Home />
            </Route>
            <Route exact path="/login" component={LoginFullPage}/>
            
            <ProtectedRoute path="/" stateProps={props} />
                    
        </Switch>
                </Router>
                </Suspense>
                </Fragment>
            )
        };
            
              
const mapStateToProps = state => ({
    user: state.User.currentUser,
    token: state.User.token,
    org: state.Organisation
});

const mapDispatchToProps = dispatch => ({
    loadUser: () => dispatch(loadUser()),
    loadOrg: (id) => dispatch(loadOrg(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(AppMain);