import {
    toast,
    Bounce
  } from 'react-toastify';

  export const WasteTypeWaste = "WasteTypeWaste"
  export const WasteTypeServing = "WasteTypeServing"
  export const WasteTypePortionsServed = "WasteTypePortionsServed"
  export const WasteTypePortionsCooked = "WasteTypePortionsCooked"

  
  export const notify = (type, msg) => toast(msg, {
    transition: Bounce,
    closeButton: true,
    autoClose: 5000,
    position: 'bottom-center',
    type: type
  });

  export const getToken = () => {
    const token = localStorage.getItem("matomatic-token");
    if (token) {
      return token
    } else {
      return null
    }
  }
  export const setToken = (token) => localStorage.setItem("matomatic-token", token);
  export const removeToken = () => localStorage.removeItem("matomatic-token");


  // The activeId is a genius hack for to set active class on selected item
  export const getOrgNav = (kitchens, org, activeId) => {
    var list = [{
      icon: 'pe-7s-culture',
      label: 'Kök',
      content: []
    }]

    if (org && org.name) {
      list[0].label = org.name
      list[0].id='root'
      list[0].ref='root'
      list[0].to='root'
    }

    if (kitchens) {
      
      const kitchenIds = Object.keys(kitchens).sort((a,b)=>{ return (kitchens[a].name > kitchens[b].name)?1:-1})

      for (const kitchenId of kitchenIds) {
        var content = []

        if (kitchens[kitchenId]["subkitchens"]) {
          const subtree1 = kitchens[kitchenId]["subkitchens"]
          const kitchenIds2 = Object.keys(subtree1)
          for (const kitchenId2 of kitchenIds2) {
            var content2 = []

            if (subtree1[kitchenId2]["subkitchens"]) {
              const subtree2 = subtree1[kitchenId2]["subkitchens"]
              const kitchenIds3 = Object.keys(subtree2)
              for (const kitchenId3 of kitchenIds3) {
                content2.push({
                  label: subtree2[kitchenId3]['name'],
                  id: kitchenId3,
                  icon: ''+kitchenId3 === ''+activeId ? 'activehack' : null,
                  to: '#/'+subtree2[kitchenId3]['name'],
                })
              }//end subloop 2
            }


            content.push({
              label: subtree1[kitchenId2]['name'],
              id: kitchenId2,
              to: '#/'+subtree1[kitchenId2]['name'],
              icon: ''+kitchenId2 === ''+activeId ? 'activehack' : null,
              content: content2
            })
        }//end subloop 1
        } 
      
        list[0].content.push({
          label: kitchens[kitchenId]['name'],
          id: kitchenId,
          to: '#/'+kitchens[kitchenId]['name'],
          icon: ''+kitchenId === ''+activeId ? 'activehack' : null,
          content: content
        })

      } //end main loop
    }
    //console.log('LIST ', list)
    return list
  }

  export const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export const getWeightFromAggregate = (type, aggData, mealtypeId, procId, catId) => {
  //console.log('getWeightFromAggregate ' + mealtypeId + '_' + procId + '_' + catId)
  let weight = null
  if (aggData != null) {
    for (var wasteItem of aggData) {
      
      if (type === WasteTypeWaste) {
        if (wasteItem['mealtype_id'] === mealtypeId && wasteItem['process_id'] === procId && wasteItem['category_id'] === catId) {
          //console.log('... found waste weight: ', wasteItem.weight)
          weight = wasteItem.weight
        }
      } else if (type === WasteTypeServing) { //Skip process check
        if (wasteItem['mealtype_id'] === mealtypeId && wasteItem['category_id'] === catId) {
          //console.log('... found serving weight: ', wasteItem.weight)
          weight = wasteItem.weight
        }
      } else if (type === WasteTypePortionsCooked) { //Only Mealtype interesting
        if (wasteItem['mealtype_id'] === mealtypeId) {
          //console.log('... found portion cooked amount: ', wasteItem.ordered_portion)
          weight = wasteItem.ordered_portion
        }
      } else if (type === WasteTypePortionsServed) { //Only Mealtype interesting
        if (wasteItem['mealtype_id'] === mealtypeId) {
          //console.log('... found portion served amount: ', wasteItem.portion)
          weight = wasteItem.portion
        }
      }


    }
  }
  return weight
}

  



  