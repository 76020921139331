import * as BackendAPI from '../BackendAPI/index'
import * as Utils from '../Utils/index'
import { loadOrg } from './Organisation'


const initialState = {
    currentUser: {
      consent_level: null,
      email: null,
      id: null,
      name: null,
      organization_id: null,
      primary_kitchen: 0,
      userlevel: null
    },
    token: null,
    isLoading: false,
    loadingMessage: "Logga in för att se kök",
    error: null
  }

  export const LOGIN_USER = 'LOGIN_USER'
  export const LOGOUT_USER = 'LOGOUT_USER'
  

  const LOAD_USER_START = 'LOAD_USER_START'
  const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS'
  const LOAD_USER_ERROR = 'LOAD_USER_ERROR'

  const loadUserStart = () => {
    return {
      type: LOAD_USER_START
    };
  };
  
  const loadUserSuccess = (user) => {
    return {
      type: LOAD_USER_SUCCESS,
      user
    }
  };
  
  const loadUserFailure = (error) => {
    return {
      type: LOAD_USER_ERROR,
      error
    };
  };

  export const setLoginUser = currentUser => ({
    type: LOGIN_USER,
    payload: currentUser
  });

  export const setLogoutUser = () => ({
    type: LOGOUT_USER
  });

  export const loadUser = () => (dispatch) => {
    let token = Utils.getToken()
    if (!token) {
      //console.log('No token, no load user');
      return
    } else {
      // Fire start action to display a loading indicator for example
      dispatch(loadUserStart());
      // Return the promise the thunk middleware
      return BackendAPI.loadCurrentUser().then(   
        // on success, fire successful action.
        response => dispatch(loadUserSuccess(response.data.user)),
        // on failure, fire failure action to e.g. show error message to user
        error => dispatch(loadUserFailure(error))
      )//.then(dispatch(loadOrg(6)));
    }
  };

  
  export default function reducer(state = initialState, action) {
        //console.log('USER REDUCER: ', action.type);
        //console.log('...payload: ', action.payload);
        switch (action.type) {
          case LOAD_USER_START:
              return {
                ...state,
                isLoading: true,
                loadingMessage: 'Läser användarinfo'
              };
      case LOAD_USER_SUCCESS:
        //console.log('Success response: ', action.user);
          return {
              ...state,
              isLoading: false,
              currentUser: action.user,
              token: Utils.getToken()
          }
      case LOAD_USER_ERROR:
        console.log('Error response: ', action.error);
        Utils.notify('error', 'Fel när användare skulle laddas: ' + action.error.message);
          return {
              ...state,
              isLoading: false,
              error: action.error
          };
        case LOGIN_USER:
            Utils.setToken(action.payload.token);
            //localStorage.setItem('matomatic-token', action.payload.token);
            return {...state, currentUser: action.payload.user, token: action.payload.token}
        case LOGOUT_USER:
            Utils.removeToken();
            //localStorage.removeItem('matomatic-token');
            return {...initialState}
        default:
            //console.log('REDUCER user: DEFAULT')
            return state;
      }
    }