import { createStore, combineReducers, applyMiddleware , compose} from 'redux';
import thunk from 'redux-thunk';
import reducers from '../reducers';
import jwtDecode from 'jwt-decode'
import * as Utils from '../Utils/index'

const checkTokenExpiration = store => next => action => {
  const token = Utils.getToken()
  if (token) {
    //console.log('Middleware2 token', jwtDecode(token));
    if (jwtDecode(token).exp < Date.now() / 1000) {
      next(action);
      localStorage.clear();
    }
  } else {
    //console.log('Middleware2 NO token');
  }
  next(action);
};

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore() {
  return createStore(
    combineReducers({
      ...reducers
    }),
    composeEnhancer(applyMiddleware(thunk, checkTokenExpiration, asyncDispatchMiddleware))
  );
}

const asyncDispatchMiddleware = store => next => action => {
  let syncActivityFinished = false;
  let actionQueue = [];

  function flushQueue() {
    actionQueue.forEach(a => store.dispatch(a)); // flush queue
    actionQueue = [];
  }

  function asyncDispatch(asyncAction) {
    actionQueue = actionQueue.concat([asyncAction]);

    if (syncActivityFinished) {
      flushQueue();
    }
  }

  const actionWithAsyncDispatch =
      Object.assign({}, action, { asyncDispatch });

  next(actionWithAsyncDispatch);
  syncActivityFinished = true;
  flushQueue();
};